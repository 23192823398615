/**Created by liaoyingchao on 11/24/22.*/

<template>
  <div class="delivery-men page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="配送员姓名">
            <el-input v-model="form.name" clearable placeholder="请输入配送员姓名"></el-input>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
            <el-button :icon="Plus" @click="newEvent">添加配送员</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="dmName"
                    label="配送员姓名"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="dmPhone"
                    label="配送员手机号"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="dmCardId"
                    label="配送员身份证"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="创建时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="修改时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="160">
              <template #default="scope">
                <el-button @click="editEvent(scope.row)" type="primary" text>编辑</el-button>
                <el-button @click="deleteEvent(scope.row)" type="danger" text>删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            :title="editTitle"
            width="60%"
            destroy-on-close
    >
      <div class="dialog-content">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="120px">
          <el-form-item label="配送员姓名" prop="name">
            <el-input v-model="editForm.name" placeholder="请输入配送员姓名" maxlength="30"/>
          </el-form-item>
          <el-form-item label="配送员手机号" prop="phone">
            <el-input v-model="editForm.phone" placeholder="请输入配送员手机号" maxlength="30"/>
          </el-form-item>
          <el-form-item label="配送员身份证" prop="cardId">
            <el-input v-model="editForm.cardId" placeholder="请输入配送员身份证" maxlength="30"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">保存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue'
  import {
    Search,
    Plus,
  } from '@element-plus/icons-vue'
  import TableContainer from '@/components/table-container/index'
  import FormCheck from "@/utils/formCheck";
  import deliveryApi from '@/api/delivery'
  import {ElMessage, ElMessageBox} from 'element-plus';
  import {timeYYYY_MM_DD_HH_mm_ss} from '@/utils/time-format.js'

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const form = reactive({
    name: ''
  })

  function getList(data) {
    return deliveryApi.dmList(data);
  }

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }

  let dialogVisible = ref(false)
  const editTitle = ref('')

  const editForm = reactive({
    id: '',
    name: '',
    phone: '',
    cardId: '',
  })

  const formRules = {
    name: FormCheck.required('请输入配送员姓名'),
    phone: [FormCheck.required('请输入配送员手机号'), FormCheck.justPhone()],
    cardId: FormCheck.justIdCard(),
  }

  const Ref_editForm = ref(null)

  function newEvent() {
    dialogVisible.value = true
    editTitle.value = '新建配送员'

    editForm.id = ''
    editForm.name = ''
    editForm.phone = ''
    editForm.cardId = ''
  }

  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        if (editForm.id == '') {
          pageLoading.value = true
          deliveryApi.dmCreate({
            deliveryMan: {
              dmName: editForm.name,
              dmPhone: editForm.phone,
              dmCardId: editForm.cardId,
            }
          }).then(res => {
            if (res.code == 0) {
              dialogVisible.value = false
              filterEvent();
            } else {
              ElMessage.error(res.msg);
            }
          }).finally(() => {
            pageLoading.value = false
          })
        } else {
          pageLoading.value = true
          deliveryApi.dmUpdate({
            deliveryMan: {
              dmId: editForm.id,
              dmName: editForm.name,
              dmPhone: editForm.phone,
              dmCardId: editForm.cardId,
            }
          }).then(res => {
            if (res.code == 0) {
              dialogVisible.value = false
              // filterEvent();
              TabelContainer.value.loadData()
            } else {
              ElMessage.error(res.msg);
            }
          }).finally(() => {
            pageLoading.value = false
          })
        }
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  function editEvent(item) {
    dialogVisible.value = true
    editTitle.value = '编辑配送员'

    editForm.id = item.dmId
    editForm.name = item.dmName
    editForm.phone = item.dmPhone
    editForm.cardId = item.dmCardId
  }

  function deleteEvent(item) {
    ElMessageBox.confirm('您确定需要删除该配送员吗？', '删除提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      pageLoading.value = true
      deliveryApi.dmDelete({
        dmId: item.dmId
      }).then(res => {
        if (res.code == 0) {
          // filterEvent();
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    }).catch(() => {

    })
  }
</script>

<style lang="stylus" scoped type="text/stylus">
  .delivery-man {
    .dialog-content {
      max-height 60vh;
      overflow-y auto;
      padding-right 40px;
    }
  }
</style>